import type { CollectionResponse, ItemResponse } from "@/data/__generated__/types/generated";

import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type AlreadySeenItemType = "collection" | "product";

interface AlreadySeenItem {
	data: CollectionResponse | ItemResponse;
	type: AlreadySeenItemType;
}

interface AlreadySeenState {
	addItem: (item: AlreadySeenItem) => void;
	getItems: () => AlreadySeenItem[];
	items: AlreadySeenItem[];
	removeItem: ({ id, type }: { id: number; type: AlreadySeenItemType }) => void;
}

const useAlreadySeen = create<AlreadySeenState>()(
	persist(
		(set, get) => ({
			items: [],
			addItem: (item: AlreadySeenItem) => {
				set((state) => {
					if (state.items.some((existingItem) => existingItem.data.id === item.data.id)) {
						return state;
					}
					const newItems = [item, ...state.items];

					if (newItems.length > 4) {
						newItems.length = 4;
					}

					return { ...state, items: newItems };
				});
			},
			removeItem: ({ id }: { id: number; type: AlreadySeenItemType }) => {
				set((state) => ({
					...state,
					items: state.items.filter((item) => item.data.id !== id),
				}));
			},
			getItems: () => {
				return get().items;
			},
		}),
		{
			name: "alreadySeen-storage",
			storage: createJSONStorage(() => localStorage),
		}
	)
);

export default useAlreadySeen;
