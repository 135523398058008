import type { ItemResponse } from "@/data/__generated__/types/generated";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";
import Link from "next/link";

import AddFavoriteButton from "../AddFavoriteButton";
import CountryBadge from "../ui/CountryBadge";

import { useUserContext } from "@/app/providers/UserProvider";
import { CMS_URL } from "@/constants";
import { getImageSource } from "@/helpers";
import useAlreadySeen from "@/hooks/useAlreadySeen";
import useFavorites from "@/hooks/useFavorites";
import useStore from "@/hooks/useStore";
import { cn } from "@/libs/utils";
import { ProductStatus, ThemeColor } from "@/types";

import useActions from "./hooks";

type ProductCardProps = {
	product: ItemResponse;
};

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
	const user = useUserContext();
	const favorites = useFavorites();
	const alreadySeen = useAlreadySeen();
	const store = useStore();

	const isOutOfStock =
		Boolean(product.status) && product.status !== ProductStatus.inStock && product.status !== ProductStatus.available;
	const isProductInCart = store.items.findIndex((item) => item.data.id === product.id) > -1;

	const { filteredProperties, formatPrice, handleAddToCart } = useActions({
		properties: product?.properties || [],
		price: product?.price,
		store,
		product,
	});

	if (!product) return null;

	const formatedPrice = formatPrice();
	const buttonText = isOutOfStock
		? "Нет в наличии"
		: product.status === ProductStatus.available
			? "Под заказ"
			: isProductInCart
				? "В корзине"
				: "В корзину";

	const isFavorite = favorites.products.findIndex((item) => item.id === product.id) > -1;

	const onFavoriteClick = () => {
		if (isFavorite) {
			favorites.removeItem({ id: product.id, type: "product" });
		} else {
			favorites.addItem({ data: product, type: "product" });
		}
	};

	const addToSeenStorage = () => {
		alreadySeen.addItem({ data: product, type: "product" });
	};

	return (
		<div
			className={cn(
				"w-full rounded-3xl overflow-hidden flex flex-col bg-white hover:shadow-md transition duration-300",
				isOutOfStock && "grayscale"
			)}
		>
			<div className="min-h-[192px] max-h-[192px] relative sm:h-[120px]">
				<Link
					href={`/catalog/${product.collectionSlug || product.categorySlug}/${product.id}.html`}
					onClick={addToSeenStorage}
				>
					<div className="w-full min-h-[192px] max-h-[192px] flex items-center justify-center border-b border-backgroundColor">
						<img
							width={280}
							height={192}
							alt={product.title}
							className="h-full max-h-[192px] object-contain"
							src={getImageSource({
								width: 280,
								type: "items",
								brand: product.id.toString(),
								thumbnail: product.image || "",
							})}
						/>
					</div>
				</Link>
				<CountryBadge className="absolute top-3 left-3" flag={product?.countrySlug} title={product?.countryTitle} />
				<AddFavoriteButton isFavorite={isFavorite} onClick={onFavoriteClick} className="absolute top-0 right-0" />

				{user && (
					<a
						rel="noopener noreferrer"
						href={`${CMS_URL}/_b/item/${product.id}/edit`}
						target="_blank"
						className={cn(
							"bg-primaryBlack/50 backdrop-blur-sm px-4 absolute bottom-1 right-1 flex text-white rounded-md"
						)}
					>
						<span>Редактировать</span>
					</a>
				)}
			</div>
			<Link
				className="flex-grow"
				onClick={addToSeenStorage}
				href={`/catalog/${product?.collectionSlug || product?.categorySlug}/${product.id}.html`}
			>
				<div className="p-4 pb-0 flex flex-col justify-between h-full">
					<div className="flex-grow">
						<span className="font-medium sm:text-sm">{product.title}</span>
						<div className="flex items-center text-sm mt-1 sm:flex-col sm:items-start">
							<span className="text-darkGray mr-1">Бренд</span>
							<span className="text-primaryBlack font-medium">{product?.manufacturerTitle}</span>
						</div>
						{Boolean(product.collectionTitle) && (
							<div className="flex items-center text-sm mt-1 sm:flex-col sm:items-start">
								<span className="text-darkGray mr-1">Коллекция</span>
								<span className="text-primaryBlack truncate font-medium">
									{product?.collectionTitle?.replace(product?.manufacturerTitle || "", "")}
								</span>
							</div>
						)}
						{filteredProperties.map((property) => {
							return (
								<div key={property?.name} className="flex items-center text-sm mt-1">
									<span className="text-darkGray/90 mr-1">{property?.name}</span>
									<span className="text-primaryBlack font-medium truncate">{property?.value}</span>
								</div>
							);
						})}
						<div className="flex items-center text-[13px] mt-2 bg-accentYellow font-medium text-white rounded-full w-max py-[3px] px-2">
							<span className="mr-1">Код товара:</span>
							<span>{`#${product.id}`}</span>
						</div>
					</div>
					<div>
						{product.price && !isOutOfStock && (
							<div className="font-medium text-lg sm:text-base my-4 text-primaryBlack">
								<span className="font-medium">{`от ${formatedPrice} руб/${product?.unitTitle}`}</span>
							</div>
						)}
					</div>
				</div>
			</Link>
			<div className="px-4 py-4">
				<button
					disabled={isProductInCart || isOutOfStock}
					onClick={handleAddToCart}
					className={cn(
						"text-white font-medium flex items-center gap-2 bg-accentGreen text-lg w-full rounded-xl h-12 justify-center sm:h-10 sm:text-base",
						product.status === ProductStatus.available && "bg-accentYellow",
						isProductInCart && "bg-mediumGray hover:bg-mediumGray/80"
					)}
					type="button"
					aria-label="Добавить в корзину"
				>
					<span>{buttonText}</span>
					{!isOutOfStock && !isProductInCart && <XPlitkaIcon name="cart" color={ThemeColor.white} size={24} />}
				</button>
			</div>
		</div>
	);
};

export default ProductCard;
